import React from 'react'

// GraphQL
import { getGraphSeries } from '../../../graphQL'

// Component
import SeriesCard from '../../molecules/SeriesCard'

// Styles
import { seriesContainer } from './styles.module.scss'

const SeriesPanel: React.FC = () => {
  const series = getGraphSeries()

  return (
    <div className={seriesContainer}>
      {series.map((ser) => (
        <SeriesCard key={`Series_Card${ser.short}}`} series={ser} />
      ))}
    </div>
  )
}

export default SeriesPanel
