import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

// Components
import PageTemplate from '../../../components/templates/PageTemplate'
import WorldTemplate from '../../../components/templates/WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'
import SeriesPanel from '../../../components/organisms/StoryPanel'

const LanguagePage = () => {
  const { language } = useContext(LocalizationContext)
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Historia ' : 'Story '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Lee la historia de nuestros personajes, su pasado y qué los motiva.'
              : 'Read the story of our characters, their past and what motivates them.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, mundo, trama, sinopsis'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, world, bios, story, synopsis'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate location="story">
        <SeriesPanel/>
      </WorldTemplate>
    </PageTemplate>
  )
}

export default LanguagePage
