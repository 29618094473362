import React, { useContext } from 'react'
import { navigate } from 'gatsby'

// Utils
import { getFont } from '../../../utils/utils'

// Model
import { SeriesModel } from '../../../models'

// Components
import DilanButton from '../../atoms/DilanButton'

// Localization
import { story } from '../../../config/localization'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Styles
import {
  seriesImg,
  seriesCta,
  seriesName,
  seriesCard,
  seriesButton,
  seriesDescription,
} from './styles.module.scss'

interface Props {
  series: SeriesModel
}

const SeriesCard: React.FC<Props> = ({ series }) => {
  const { language } = useContext(LocalizationContext)
  return (
    <div className={seriesCard}>
      <h3 className={`${seriesName} ${getFont('font-patua')}`}>
        {series.name[language]}
      </h3>
      <img
        className={seriesImg}
        src={`https://dilancovak.com/.img/story/${series.short}.png`}
      />
      <p className={`${seriesDescription} ${getFont('font-lato')}`}>
        {series.description[language]}
      </p>
      <div className={seriesCta}>
        <DilanButton
          type="primary"
          className={`${seriesButton} ${getFont('font-lato')}`}
          handleClick={() => {
            navigate(`/reader/${series.prefix}1`)
          }}
        >
          {story[language].read}
        </DilanButton>
        <DilanButton
          type="ghost-secondary"
          className={`${seriesButton} ${getFont('font-lato')}`}
          handleClick={() => {
            navigate(`/world/story/${series.short}`)
          }}
        >
          {story[language].learn_more}
        </DilanButton>
      </div>
    </div>
  )
}

export default SeriesCard
